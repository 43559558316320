import type { Reducer } from 'react'
import type { FilterData, SelectedNbfFilters } from '@which/shared'

import type { ProductListingPageQuery } from '../../../../generated/frontend'
import type { AppliedFilterItem, ShowMoreFilters } from '../../../../shared/types'
import type { ReviewsAction, ReviewsState } from '../ReviewsTemplateContext'

export const productListingPageReducer: Reducer<ReviewsState, ReviewsAction> = (state, action) => {
  /** change to switch when we have more than 3 cases */
  if (action.type === 'UPDATE_PRODUCT_LISTING_PAGE_DATA') {
    const { data } = action.payload
    return {
      ...state,
      productListingPageData: data.productListingPageData,
      selectedFilters: data.selectedFilters,
      fullUrl: data.fullUrl,
      filterContext: data.filterContext,
      setToastContext: data.setToastContext,
    }
  }

  return state
}

/* istanbul ignore next */
export const initialState = {
  productListingPageData: {} as ProductListingPageQuery['productListingPage'],
  selectedFilters: [] as AppliedFilterItem[],
  filterContext: {
    basicFilterData: {} as FilterData,
    needsBasedFilterData: {} as FilterData,
    filterCallback: () => null,
    setIsOverlayFiltersDisplayed: () => false,
    isOverlayFiltersDisplayed: false,
    showMoreFilters: {} as ShowMoreFilters,
    setShowMoreFilters: () => null,
    selectedNeedsBasedFilters: {} as SelectedNbfFilters,
    pageNumberOnFilterInteraction: null as number | null,
    setPageNumberOnFilterInteraction: () => null,
  },
  setToastContext: () => ({
    displayToast: false,
    error: false,
    mainText: '',
    linkText: undefined,
    linkUrl: undefined,
    onClick: undefined,
  }),
}
