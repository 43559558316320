import type { FunctionComponent } from 'react'
import React from 'react'

import classnames from 'classnames'

import { type ProductDetail } from '../../../../../../generated/frontend'
import { Product } from '../ProductDetails/Product'
import styles from './ComparisonTableV2.module.scss'

export const ComparisonPageProducts: FunctionComponent<ComparisonPageProductProps> = ({
  productDetailsForComparison,
  taxonomySlug,
  hasOneProductBadge,
  isDesktopOrAbove,
  totalProductColumns,
  tooltipOpenHandler,
  isPaidMember,
}) => (
  <section className={classnames(styles.productDetails, styles.section)}>
    <div className={styles.filler} />
    {productDetailsForComparison.map((productDetail) => (
      <Product
        key={productDetail?.businessKey}
        hasOneProductBadge={hasOneProductBadge}
        isPaidMember={isPaidMember}
        taxonomySlug={taxonomySlug}
        tooltipOpenHandler={tooltipOpenHandler}
        tag="div"
        {...productDetail}
        productScoreGauge={{ ...productDetail.productScoreGauge, variant: 'default' }}
      />
    ))}
    {isDesktopOrAbove && productDetailsForComparison.length < totalProductColumns && (
      <div className={styles.filler} />
    )}
  </section>
)

type ComparisonPageProductProps = {
  productDetailsForComparison: ProductDetail[]
  taxonomySlug: string
  hasOneProductBadge: boolean
  isDesktopOrAbove: boolean
  totalProductColumns: number
  tooltipOpenHandler: () => void
  isPaidMember: boolean
}
