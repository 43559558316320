/* istanbul ignore file */

import React from 'react'

import type { WCDRouteProps } from '..'
import { Page } from '../components/Page'

export const signupCompleteRoutes: WCDRouteProps = [
  {
    live: true,
    exact: true,
    path: '/signup-complete',
    component: () => <Page pagePath="signup-complete/SignupCompletePage" />,
  },
]
