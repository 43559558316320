import type { FunctionComponent } from 'react'
import React from 'react'
import { AccordionItem } from '@which/seatbelt'

import type { ComparisonTableData } from '../../../../../../generated/frontend'
import type { OnTableRowCheckboxArgs } from '../types'
import { handleAccordionToggle, renderTableData } from './ComparisonTableV2'

export const ComparisonPageSection: FunctionComponent<ComparisonPageSectionProps> = ({
  label,
  isDesktopOrAbove,
  tableData,
  showRefine,
  onTableRowCheckbox,
  totalProductColumns,
}) => (
  <AccordionItem
    data-testid={`accordion-section-${label.replace(' ', '-').toLowerCase()}`}
    label={label}
    content={renderTableData({
      tableData,
      showRefine,
      onTableRowCheckbox,
      accordionHeading: 'Test results',
      isDesktopOrAbove,
      totalProductColumns,
    })}
    renderOpen={isDesktopOrAbove}
    animatesOpen={false}
    callback={(isOpen: boolean) => {
      handleAccordionToggle(isOpen, 'Test results')
    }}
  />
)

type ComparisonPageSectionProps = {
  label: string
  tableData: ComparisonTableData[]
  isDesktopOrAbove: boolean
  totalProductColumns: number
  showRefine: boolean
  onTableRowCheckbox: ({ value, sectionLabel, rowLabel }: OnTableRowCheckboxArgs) => void
}
