import type { FunctionComponent } from 'react'
import React, { useEffect, useState } from 'react'
import type { NewsletterSource } from '@which/glide-ts-types'
import { Loader, TypographyV2 as Typography } from '@which/seatbelt'
import { TickIcon } from '@which/seatbelt/src/components/Icons/Navigational'
import { dynamicGa4DataLayerPush } from '@which/shared'
import classnames from 'classnames'

import { sourceMap } from '../../constants/newsletter-source-map'
import { getEnvironment, isLocal } from '../../utils/env-checks'
import styles from './NewsletterSignUp.module.scss'
import { NewsletterSignUpForm } from './NewsletterSignUpForm'

export const NewsletterSignUp: FunctionComponent<Props> = ({ className, source }) => {
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')

  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ newsletterWidget: source } as (typeof window.dataLayer)[number])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!sourceMap[source]) {
    return null
  }

  const {
    header,
    description,
    successHeader,
    successText,
    disclaimerText,
    unsubscribeText,
    buttonText = 'Get newsletter',
    withAllFields,
  } = sourceMap[source]

  const onSignUp = async (data: NewsletterFormData) => {
    const errorMessage = 'Failed to subscribe you, please try again later.'

    setLoading(true)

    const finalData = removeEmpty(data)

    try {
      const fetchResponse = await fetch(getAPIEndPoint(), {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...finalData,
          source: source.toUpperCase(),
          status: 'ACTIVE',
        }),
      })

      if (fetchResponse.ok) {
        setLoading(false)
        setSuccess(true)
        handleTracking(source, data.email, data.postcode)
      }

      if (fetchResponse.status > 300) {
        setLoading(false)
        setErrorMsg(errorMessage)
      }
    } catch (err) {
      setLoading(false)
      setErrorMsg(errorMessage)
    }
  }

  return (
    <div
      className={classnames(
        className,
        styles.newsletterSignUp,
        styles[`newsletterSignUpWrapper-${source}`]
      )}
      data-testid="newsletter-sign-up"
      id={`${source}-newsletter-sign-up`}
    >
      {loading && (
        <div className={styles.processing}>
          <div className={styles.processingLoader}>
            <Typography textStyle="sb-text-body-default-regular">Processing...</Typography>
            <Loader />
          </div>
        </div>
      )}
      {success && (
        <div className={styles.successContainer}>
          <div>
            <div className={styles.successHeaderContainer}>
              <div className={styles.successHeaderIcon}>
                <TickIcon />
              </div>
              <Typography textStyle="sb-text-heading-page-title" className={styles.successHeader}>
                Congratulations
              </Typography>
            </div>
            <Typography
              textStyle="sb-text-body-default-regular"
              className={styles.successSubHeader}
            >
              {successHeader}
            </Typography>
            <Typography textStyle="sb-text-body-x-small-regular">{successText}</Typography>
          </div>
        </div>
      )}
      {!loading && !success && (
        <NewsletterSignUpForm
          onSignUp={onSignUp}
          buttonText={buttonText}
          withAllFields={withAllFields}
          description={description}
          disclaimerText={disclaimerText}
          unsubscribeText={unsubscribeText}
          header={header}
          onFocus={onFocus}
          errorMsg={errorMsg}
        />
      )}
    </div>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  className?: string
  source: NewsletterSource
}

// onFocus should do nothing because the API no longer requires a cold start hack
const onFocus = () => {}

const getAPIEndPoint = () => {
  if (isLocal()) {
    return 'https://dev-membership.api.product.which.co.uk/newsletter/signup'
  }
  switch (getEnvironment()) {
    case 'prod':
      return 'https://prod-membership.api.product.which.co.uk/newsletter/signup'
    case 'preprod':
      return 'https://preprod-membership.api.product.which.co.uk/newsletter/signup'
    default:
      return 'https://dev-membership.api.product.which.co.uk/newsletter/signup'
  }
}

const removeEmpty = (data) => {
  return Object.keys(data)
    .filter((key) => data[key] !== '')
    .reduce((acc, key) => ({ ...acc, [key]: data[key] }), {})
}

const handleTracking = (source: string, email: string, postcode?: string) =>
  dynamicGa4DataLayerPush({
    event: 'goal_fra_signup',
    utagid: 'WHC561DP01',
    item_text: source,
    action_group: 'widget',
    user_email: email,
    user_postcode : postcode
  })

export type NewsletterFormData = {
  email: string
  firstName?: string
  lastName?: string
  postcode?: string
}
