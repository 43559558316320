import type { FunctionComponent } from 'react'
import { AccordionItem, AnimationWrapper, TypographyV2 as Typography } from '@which/seatbelt'
import { AMAZON_AFFILIATE_TAG, dynamicGa4DataLayerPush, getTaggedAmazonUrl } from '@which/shared'

import classnames from 'classnames'

import type { ProductDetail, ProductOffer } from '../../../../../../generated/frontend'
import { Link } from '../../../../../../shared/components/Link'
import type { ContentType } from '../../../../../../shared/components/TrackonomicsLink/TrackonomicsLink.tsx'
import { formatAllRetailers } from '../../../../../../shared/utils/formatRetailers'
import { ArticleTrackonomicsLink } from '../../../../../article/components/ArticleTrackonomicsLink'
import { convertPriceValueToFloat } from '../../../../utils/convert-price-value-to-float'
import { handleAccordionToggle } from './ComparisonTableV2'
import styles from './ComparisonTableV2.module.scss'

export const ComparisonWhereToBuy: FunctionComponent<ComparisonWhereToBuyProps> = ({
  products,
  businessKeys,
  isDesktopOrAbove,
  totalProductColumns = 4,
}) => {
  const handleAffiliateClick = (validOffer: ProductOffer, productName) => {
    const { priceValue, retailer, url } = validOffer
    const item_spec = new URL(url).hostname

    dynamicGa4DataLayerPush({
      event: 'tEvent',
      eventCategory: 'Where to Buy',
      eventAction: 'Go to Retailer',
      eventLabel: `${retailer.name} | ${productName} | ${convertPriceValueToFloat(
        priceValue,
        true
      )}`,
      eventValue: Math.floor(Number(convertPriceValueToFloat(priceValue))),
      item_url: url,
      item_spec,
      item_group: 'compare tray - compare retailers section',
    })
  }

  const renderLink = ({
    url,
    validOffer,
    formattedPrice,
    productName,
    retailerName,
    trackable,
  }: RenderLinkProps) => {
    const textStyle = 'sb-text-interface-body-small-regular'
    const LinkComponent = trackable ? ArticleTrackonomicsLink : Link

    const trackableProps = {
      href: url,
      contentType: 'article' as ContentType,
      ...{ className: styles.link },
      optionalTracking: {
        item_group: 'compare tray - compare retailers section',
      },
      onClick: () => handleAffiliateClick(validOffer, productName),
    }

    const nonTrackableProps = {
      href: getTaggedAmazonUrl(url, AMAZON_AFFILIATE_TAG),
      onClick: () => handleAffiliateClick(validOffer, productName),
      ...{ className: styles.link },
      'aria-label': `Buy from ${retailerName} at ${formattedPrice}`,
      'data-which-id': 'affiliate-link',
      target: '_blank',
      rel: 'nofollow',
      contentType: 'article' as ContentType,
    }

    const componentProps = trackable ? trackableProps : nonTrackableProps

    return (
      <Typography tag="span" textStyle={textStyle}>
        {formattedPrice}
        <LinkComponent {...componentProps}>
          <Typography tag="span" textStyle={textStyle}>
            <AnimationWrapper>{retailerName}</AnimationWrapper>
          </Typography>
        </LinkComponent>
      </Typography>
    )
  }

  const renderWhereToBuy = () => (
    <div
      key={`where-to-buy-section`}
      className={classnames(styles.compareSection, styles.whereToBuy)}
    >
      <div
        className={classnames(styles.productFeatureRow, {
          [styles.mobileGreyRow]: !isDesktopOrAbove,
        })}
        data-testid={'where-to-buy-row'}
      >
        <div className={styles.rowLabel}>
          <Typography textStyle="sb-text-interface-body-small-regular" tag="span">
            Compare retailers
          </Typography>
        </div>
        {products.map((product, index) => {
          const validOffers = formatAllRetailers(product.offers)
          return (
            <div className={styles.tableDataRowValue} key={`retailer-${businessKeys[index]}`}>
              {product.price && validOffers.length === 0 && (
                <Typography
                  textStyle="sb-text-interface-body-small-regular"
                  tag={'p'}
                  className={styles.offersListItem}
                >
                  {product.price}
                  <Typography
                    tag="span"
                    textStyle="sb-text-interface-body-small-regular"
                    className={styles.typicalPrice}
                  >
                    Typical price
                  </Typography>
                </Typography>
              )}
              {validOffers.length > 0 && (
                <ol className={styles.offersList}>
                  {validOffers.map((validOffer) => {
                    const { retailer, url, isTrackable, formattedPrice } = validOffer
                    return (
                      <li key={retailer.name} className={styles.offersListItem}>
                        {renderLink({
                          url,
                          validOffer,
                          formattedPrice,
                          productName: `${product.manufacturer.name} ${product.model}`,
                          retailerName: retailer.name,
                          trackable: isTrackable,
                        })}
                      </li>
                    )
                  })}
                </ol>
              )}
            </div>
          )
        })}

        {isDesktopOrAbove && products.length < totalProductColumns && (
          <div className={styles.filler} />
        )}
      </div>
    </div>
  )

  return (
    <AccordionItem
      label="Where to buy"
      content={renderWhereToBuy()}
      renderOpen={isDesktopOrAbove}
      animatesOpen={false}
      callback={(isOpen: boolean) => {
        handleAccordionToggle(isOpen, 'Where to buy')
      }}
    />
  )
}

type ComparisonWhereToBuyProps = {
  products: ProductDetail[]
  businessKeys: string[]
  isDesktopOrAbove: boolean
  totalProductColumns?: number
}

type RenderLinkProps = {
  url: string
  validOffer: ProductOffer
  formattedPrice: string
  productName: string
  retailerName: string
  trackable: boolean
}
