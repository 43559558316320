import type { FunctionComponent } from 'react'
import { ButtonLink, Picture, TypographyV2 } from '@which/seatbelt'

import classnames from 'classnames'

import type { ImageWithSources } from '../../../../generated/frontend'
import styles from './ProductHubCategoryHero.module.scss'

// This component re-implements the Seatbelt CategoryHero component so we can A/B test
// having a CTA button within it. If whc-reverse-product-hub-layout tests successfully,
// this functionality should be moved to Seatbelt so we can use it from Seatbelt directly,
// and this component can be deleted

export const ProductHubCategoryHero: FunctionComponent<ProductHubCategoryProps> = ({
  heading,
  description,
  image,
  productSlug,
  ctaText,
}) => (
  <section className={classnames(styles.categoryHero, image?.src && styles.hasImage)}>
    <div className={styles.content}>
      <div className={styles.text}>
        {heading && (
          <TypographyV2 className={styles.heading} textStyle="sb-text-heading-page-title" tag="h1">
            {heading}
          </TypographyV2>
        )}

        {description && (
          <TypographyV2
            className={classnames('sb-margin-top-2', styles.description)}
            tag="p"
            textStyle="sb-text-body-default-regular"
          >
            {description}
          </TypographyV2>
        )}

        {productSlug && ctaText && (
          <ButtonLink
            className={classnames({
              [styles.ctaButton]: !description,
            })}
            href={`/reviews/${productSlug}`}
            data-which-id="producthub-button-top"
          >
            {ctaText}
          </ButtonLink>
        )}
      </div>
    </div>

    {image?.src && (
      <Picture
        className={styles.image}
        src={image.src || ''}
        sources={image.sources}
        alt={image.alt || ''}
        aspectRatioMain="two-to-one"
        aria-hidden="true"
        imageClassName={styles.heroImage}
        lazyLoad={false}
      />
    )}
  </section>
)

///////// IMPLEMENTATION /////////

export type ProductHubCategoryProps = {
  heading: string
  description: string
  productSlug?: string
  ctaText?: string
  image: ImageWithSources | undefined
}
