import type { FunctionComponent } from 'react'
import { useEffect, useMemo } from 'react'
import { getCookie } from '@which/shared'
import { gql, useQuery } from '@apollo/client'

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { useKindeAuth } from '@kinde-oss/kinde-auth-react'
import loadable from '@loadable/component'
import otel from '@opentelemetry/api'
import newrelic from 'newrelic'

import { useSavedItemsQuery } from '../../generated/frontend'
import { isLocal } from '../../shared'
import { Loader } from '../../shared/components/Loader'
import { PagePropsContext } from '../../shared/usePageProps'
import type { PageProps } from '../'

export const Page: FunctionComponent<PageProps> = ({
  pagePath,
  template,
  rootCollectionName,
  context,
}) => {
  const MatchedPage = useMemo(
    () =>
      loadable(
        () =>
          import(
            /* webpackInclude: /[a-zA-Z\-]*\/[a-zA-Z]*Page\.tsx$/ */ `../../pages/${pagePath}`
          ),
        {
          fallback: <Loader />,
        }
      ),
    [pagePath]
  )
  const kindeLoginFeature = useFeatureIsOn('paywall-kinde-login')
  const kinde = useKindeAuth()

  const { data: { userAccessState } = {} } = useQuery(USER_ACCESS_STATE_QUERY)

  const isPaidMember = ['AUTHENTICATED_FULL_ACCESS', 'FULL_ACCESS'].includes(
    userAccessState?.transformTypeDecision ?? ''
  )

  // We want to fetch saved products only for below templates - add more template names when we want to expand it to other areas of the website
  const isAllowedPageTemplate = [
    'Reviews Product Listing',
    'Reviews Product Page',
    'Reviews Product Comparison',
    'Article Advice Multi Page',
    'Article Advice Single Page'
  ].includes(template ?? '')
  const skipFetchingSavedItems = !isPaidMember || !isAllowedPageTemplate

  const { data } = useSavedItemsQuery({
    fetchPolicy: 'cache-and-network',
    skip: skipFetchingSavedItems,
  })
  const savedItems = data?.savedItems ?? []

  const paywallWCDAutoLogoutFeature = useFeatureIsOn('paywall-wcd-auto-logout')

  if (typeof newrelic !== 'undefined') {
    newrelic.setTransactionName(pagePath)
  }
  if (typeof otel !== 'undefined') {
    const span = otel.trace.getSpan(otel.context.active())
    span?.updateName(pagePath)
  }

  useEffect(() => {
    if (kindeLoginFeature) {
      const kindeCookie = getCookie(document.cookie, 'sessionId')
      const domain = isLocal() ? 'localhost' : '.which.co.uk;'
      const setKindeSession = async () => {
        if (!kindeCookie && kinde && !kinde.isLoading && kinde.isAuthenticated && kinde.getToken) {
          const token = await kinde.getToken()
          document.cookie = `sessionId=${token};path=/; domain=${domain};`
          document.cookie = `blaize_session=${token};path=/; domain=${domain};`
          location.reload()
        }
      }
      setKindeSession()
    }
  })

  useEffect(() => {
    const blaizeCookie = getCookie(document.cookie, 'blaize_session')
    const domain = isLocal() ? 'localhost' : '.which.co.uk;'

    if (
      blaizeCookie &&
      userAccessState?.transformTypeDecision === 'ANONYMOUS_NO_ACCESS' &&
      paywallWCDAutoLogoutFeature
    ) {
      document.cookie = `blaize_session=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${domain};`

      location.reload()
    }
  }, [paywallWCDAutoLogoutFeature, userAccessState?.transformTypeDecision])

  return (
    <PagePropsContext.Provider
      value={{
        template,
        rootCollectionName,
        context,
        userAccessState,
        savedItems,
      }}
    >
      <MatchedPage />
    </PagePropsContext.Provider>
  )
}

export const USER_ACCESS_STATE_QUERY = gql`
  query userAccessState {
    userAccessState {
      userLoggedIn
      transformTypeDecision
    }
  }
`
