/* eslint-disable @typescript-eslint/no-unused-vars */
import type { FunctionComponent } from 'react'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Grid, GridItem, Link, PageTitle, Typography } from '@which/seatbelt'
import { dynamicGa4DataLayerPush } from '@which/shared'

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import classnames from 'classnames'

import type { Meta } from '../../../generated/frontend'
import { useLoginMutation } from '../../../generated/frontend'
import { ErrorComponent } from '../../../shared/components/Error'
import { InfoBanner, type Props } from '../../../shared/components/InfoBanner'
import { useBaseMetaTags } from '../../../shared/hooks/useBaseMetaTags'
import { Links, LoginPageBannerText } from '../data'
import { accountPagesDataLayer, setNewRelicTransaction } from '../utils'
import styles from './LoginPage.module.scss'
import { processLoginMutationResponse } from './loginUtils'
import OneStepLoginForm from './OneStepLoginForm'
import TwoStepLoginForm from './TwoStepLoginForm'

const LoginPage: FunctionComponent = () => {
  const [content, setContent] = useState('')
  const [context, setContext] = useState<Props['context']>('error')
  const [stayLoggedIn, setStayLoggedIn] = useState(true)
  const [showInfoBanner, setShowInfoBanner] = useState(false)
  const [title, setTitle] = useState('')
  const isTwoStepLogin = useFeatureIsOn('paywall-two-step-login')

  const { getBaseMetaTags } = useBaseMetaTags()

  const [loginMutation, { data, error, loading }] = useLoginMutation()
  setNewRelicTransaction('account/login')

  const metaTagObj = {
    canonical: 'https://www.which.co.uk/login',
    title: "Login to which.co.uk | Discover Best Buys & Don't Buys",
    fbAppId: '320949621749168',
    desc: 'From this page members can log directly into which.co.uk. Access thousands of expertly tested independent reviews to help you make confident choices. Please have your email address or username and password ready.',
  }

  const getMetaTags = ({ meta, imageUrl, twitterCard }: LoginMetaArgs) => [
    ...getBaseMetaTags({ meta, imageUrl, twitterCard }),
  ]

  const showAuthenticationError = (copy: (typeof LoginPageBannerText)['generic']) => {
    setShowInfoBanner(true)
    setContext('error')
    setContent(copy.authErrorContentText)
    setTitle(copy.authErrorText)
    dynamicGa4DataLayerPush({
      event: 'notification_error',
      action_group: 'login',
      item_text: copy.authErrorText,
      utagid: 'AUP4858DP01',
    })
  }

  useEffect(() => {
    if (data && data.login) {
      const { login } = data

      processLoginMutationResponse({
        login,
        stayLoggedIn,
      })

      if (login.__typename === 'AuthenticationError') {
        showAuthenticationError(
          isTwoStepLogin ? LoginPageBannerText.password : LoginPageBannerText.generic
        )
      }
    }
  }, [data, isTwoStepLogin, stayLoggedIn])

  useEffect(() => {
    if (document.cookie.includes('ref=reset-password')) {
      document.cookie = 'ref= ; '
      setShowInfoBanner(true)
      setContent(LoginPageBannerText.generic.Success)
      setContext('correct')
      setTitle('')
    }
  }, [])

  useEffect(() => {
    if (error) {
      if (
        'newrelic' in window &&
        typeof window.newrelic !== null &&
        typeof window.newrelic === 'object' &&
        'noticeError' in window.newrelic! &&
        typeof window.newrelic.noticeError === 'function'
      ) {
        window.newrelic.noticeError(error)
      }
      dynamicGa4DataLayerPush({
        event: 'notification_error',
        action_group: 'login',
        item_text: `500 error: ${error.message}`,
        utagid: 'AUP4858DP01',
      })
    }
  }, [error])

  if (error) {
    return <ErrorComponent error={error} />
  }

  const LoginForm = isTwoStepLogin ? TwoStepLoginForm : OneStepLoginForm

  return (
    <>
      <Helmet>
        {getMetaTags({
          meta: metaTagObj,
          imageUrl: 'https://www.which.co.uk/login',
          twitterCard: 'summary_large_image',
        })}
        <script>
          {accountPagesDataLayer({
            content_type: 'login',
          })}
        </script>
      </Helmet>

      <Grid className={classnames(styles.loginPage, showInfoBanner && styles[context])}>
        <GridItem span={{ medium: 6, large: 6 }} columnStart={{ medium: 4, large: 4 }}>
          {showInfoBanner && <InfoBanner content={content} context={context} title={title} />}
          <PageTitle pageTitle="Log into Which?" pageTitleTag="h1" />
          <LoginForm
            loading={loading}
            showAuthenticationError={showAuthenticationError}
            handleOnSubmit={(username, password, shouldStayLoggedIn) => {
              loginMutation({ variables: { username, password } })
              setStayLoggedIn(shouldStayLoggedIn)
              setShowInfoBanner(false)
            }}
            stayLoggedInState={{ stayLoggedIn, setStayLoggedIn }}
          />
          <Typography
            textStyle="sb-text-interface-body-small-regular"
            className={styles.loginPageSignupLink}
          >
            Not a Which? member?{' '}
            <Link
              data-which-id="link"
              href={Links.join}
              textStyle="sb-text-interface-body-small-regular"
            >
              Sign up now
            </Link>
          </Typography>
        </GridItem>
      </Grid>
    </>
  )
}

export default LoginPage

/////// IMPLEMENTATION /////////

type LoginMetaArgs = {
  meta: Partial<Meta>
  imageUrl: string
  twitterCard: string
}

interface StayLoggedInState {
  stayLoggedIn: boolean
  setStayLoggedIn: React.Dispatch<React.SetStateAction<boolean>>
}

export type LoginFormProps = {
  handleOnSubmit: (username: string, password: string, stayLoggedIn: boolean) => void
  showAuthenticationError: (copy: (typeof LoginPageBannerText)['generic']) => void
  loading: boolean
  stayLoggedInState: StayLoggedInState
}
