import { useFeatureValue } from '@growthbook/growthbook-react'

import type { Recommendations } from '../../../../generated/frontend'
import { RecommendedContent } from '../RecommendedContent'

interface RecommendedForYouContainerProps {
  recommendations: Recommendations
  whichId: string
}

export const RecommendedForYouContainer = ({
  recommendations,
  whichId,
}: RecommendedForYouContainerProps) => {
  const shouldUseSegmentModel = useFeatureValue('use-segment-recommentations', false)
  const modelNameToUse = shouldUseSegmentModel ? 'segment/mixed/adviceOnly' : 'mixed/adviceOnly'

  const modelToUse = recommendations.models.find((model) => model.model == modelNameToUse)

  const { content, model } = modelToUse ?? {}

  if (content && model) {
    return <RecommendedContent content={content} whichId={whichId} modelId={model} />
  }

  return null
}
